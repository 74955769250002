<template>
  <div v-html="stylesComputed"></div>
</template>

<script>
import Base from './Base.vue';
export default {
  extends: Base,
  data() {
    return {
      error: false
    };
  },
  computed: {
    stylesComputed() {
      return `<style>
                ${this.data.style || ''}
              </style>`;
    }
  }
};
</script>

<style lang="scss">
</style>
