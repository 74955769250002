class LocalStorage {
  save(key, value) {
    try {
      if (process.client) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      this.remove(key);
      console.error(error);
      throw error;
    }
  }

  get(key) {
    try {
      if (process.client) {
        return JSON.parse(localStorage.getItem(key));
      }
    } catch (error) {
      this.remove(key);
      console.error(error);
      throw error;
    }
  }

  remove(key) {
    try {
      if (process.client) {
        localStorage.removeItem(key);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
export { LocalStorage };
