<script>
import { get } from './cms';
import { LocalStorage } from '../../services/localstorage';
const lc = new LocalStorage();
export default {
  props: {
    value: {
      type: [String, Object],
      required: true
    },
    cache: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: null
    };
  },
  watch: {
    value: {
      handler: 'onValueChange',
      immediate: true
    }
  },
  mounted() {
    if (this.cache && typeof this.value === 'string') {
      try {
        const cache = lc.get(this.value);
        if (cache) {
          this.data = cache;
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  methods: {
    onValueChange(val) {
      if (typeof val === 'object') {
        this.data = val;
      } else if (typeof val === 'string') {
        this.getDataByKey(val);
      }
    },
    async getDataByKey(key) {
      try {
        const res = await get({ key });
        if (res.docs.length) {
          const item = res.docs[0];
          if (item.exists) {
            this.data = item.data();
            if (typeof this.value === 'string' && this.cache) {
              lc.save(this.value, this.data);
            }
          } else {
            lc.remove(this.value);
            this.$emit('error');
          }
        } else {
          lc.remove(this.value);
          this.$emit('error');
        }
      } catch (error) {
        this.$emit('error');
        throw error;
      }
    }
  }
};
</script>

<style>
</style>
